<div class="mt-3 w-100 px-3">
  <div class="d-flex justify-content-center">
    <h1 class="bold my-3">{{'general.app_name' | translate}}</h1>
  </div>

  <form class="login-form" (ngSubmit)="login(loginForm)" [formGroup]="loginForm" novalidate>
    <mat-card class="w-100">
      <mat-card-content class="d-flex flex-column">
        <mat-form-field>
          <mat-label>{{'login.email' | translate}}</mat-label>
          <input matInput type="email" formControlName="email" />
          <mat-icon matSuffix>perm_identity</mat-icon>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'login.password' | translate}}</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-icon matSuffix>lock</mat-icon>
        </mat-form-field>
        <a class="forgotten-password align-self-end d-flex align-items-center pointer mb-4"
          (click)="showResetPasswordLinkDialog()">
          <mat-icon>arrow_right</mat-icon>
          {{'login.forgot_password' | translate}}
        </a>
       <!-- <mat-checkbox color="primary" formControlName="cgu_checkbox" class="cgu-checkbox">
          <div class="cgu-text-container">
              <span class="cgu-link">{{ 'login.cgu_agreement' | translate }}</span>
              <a target="_blank" rel="noopener noreferrer" (click)="openDoc('cgu')" class="pdf-link">
                      {{'login.cgu_link' | translate}}
                  </a>
          </div>
      </mat-checkbox>
      <mat-checkbox class="mb-4 cgu-checkbox" color="primary" formControlName="privacy_checkbox">
          <div class="cgu-text-container">
              <span class="cgu-link">{{ 'login.privacy_policy_agreement' | translate }}</span>
              <a target="_blank" rel="noopener noreferrer" (click)="openDoc('privacy_policy')" class="pdf-link">
                      {{'login.privacy_policy_link' | translate}}
                  </a>
          </div>
      </mat-checkbox>-->
        <div class="d-flex justify-content-center">
         <!-- <button mat-raised-button color="primary" class="w-100"
            [disabled]="!loginForm.valid || !loginForm.controls.cgu_checkbox.value || !loginForm.controls.privacy_checkbox.value">
            <mat-icon>login</mat-icon>
            {{'login.connect' | translate}}
          </button>-->
          <button mat-raised-button color="primary" class="w-100">
            <mat-icon>login</mat-icon>
            {{'login.connect' | translate}}
          </button>
        </div>
        <div class="d-flex flex-column align-items-center">
          <hr class="w-100 my-4">
          <ng-container *ngIf="(getOAuth2Providers() | async).length">
            <ng-container *ngFor="let provider of getOAuth2Providers() | async">
              <!--<button mat-raised-button class="align-self-center px-3 py-1 w-100" color="primary"
                *ngIf="provider.id !== 'digitalpass-bo'" (click)="loginWithOAuth2(provider.url)"
                [disabled]="!loginForm.controls.cgu_checkbox.value || !loginForm.controls.privacy_checkbox.value">
                {{'login.connect_via' | translate: {provider: provider.label} }}
              </button>-->
              <button mat-raised-button class="align-self-center px-3 py-1 w-100" color="primary"
              *ngIf="provider.id !== 'entraid-bo'" (click)="loginWithOAuth2(provider.url)">
              {{'login.connect_via' | translate: {provider: provider.label} }}
            </button>
            </ng-container>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
